<template>
  <div>
    <v-container fluid>

      <div class="main">
        <v-row justify="center">
          <v-col cols="12" lg="8">
            <div class="text-center">
              <img src="../assets/401.svg" width="400">
              <h1 class="mb-5">Désolé, vous n'êtes pas autorisé à accéder à cette page.</h1>
              <v-btn color="primary" class="rounded-lg" depressed to="/">Dashboard</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  data(){
    return {
      breadcrumbs: [
        {text: 'Dashboard', disabled: false, to: '/'},
        {text: 'Page non trouvée !', disabled: false},
      ],
    }
  }
}
</script>

<style scoped>

</style>